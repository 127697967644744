import React from "react";
import {Link} from 'gatsby'

import {background, link, linkContainer, title, titleLink, viewportHeader} from "../styles/index.css";

import YosemiteImage from "../images/yosemite.jpg";
import YosemiteVideo from "../images/yosemite.mp4";

const IndexPage = () => {
    return (
        <div className={background}>
            <video playsInline autoPlay muted loop poster={YosemiteImage} id="bgvid">
                <source src={YosemiteVideo} type="video/mp4"/>
            </video>
            <header className={viewportHeader}>
                <h1 className={title}>
                    <Link to="/info" className={titleLink}>Eclipse IT Consulting</Link>
                </h1>
                <div className={linkContainer}>
                    <Link to="/info" className={link}>Enter</Link>
                </div>
            </header>
        </div>
    );
};

export const Head = () => (
    <>
        <title>Eclipse IT Consulting, UK. The Digital Experts</title>
        <meta
            name="description"
            content="Eclipse IT Consulting, UK, provide expertise in delivering digital technologies, using an Agile approach. Established in 2009, we provide expertise in areas including; Digital Solution Architecture; Agile Software Engineering; Test Driven Development; Continuous Integration; and Agile Coaching."
        />
    </>
);

export default IndexPage;
